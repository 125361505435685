import { render, staticRenderFns } from "./Project.vue?vue&type=template&id=19a24060&scoped=true&"
import script from "./Project.vue?vue&type=script&lang=ts&"
export * from "./Project.vue?vue&type=script&lang=ts&"
import style0 from "./Project.vue?vue&type=style&index=0&id=19a24060&lang=scss&scoped=true&"
import style1 from "./Project.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a24060",
  null
  
)

export default component.exports