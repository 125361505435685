import { Vue, Component } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';

@Component
export default class ChangeViewMixin extends Vue {
    setView(view: 'list' | 'map' | 'detail') {
        projectModule.changeView(view);

        this.$router.push({
            query: { ...this.$route.query, view }
        });
    }
}
