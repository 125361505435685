const typologyType = {
    1: 'Duplex',
    2: 'Triplex',
    3: 'Flat'
};

export function models(
    value?: (keyof typeof typologyType)[]
): string | undefined {
    if (!value) return '';
    return value
        .filter((item, pos, self) => self.indexOf(item) === pos)
        .map(item => typologyType[item])
        .join(' -');
}
