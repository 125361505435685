







































































































































































































































































































































































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import TableModel from './TableModel.vue';
import { typeProject } from '@/filters/type-project';
import { models } from '@/filters/models';
import { banks } from '@/filters/banks';
import { projectPhase } from '@/filters/project-phase';
import moment from 'moment';
import { Unselect } from '@/store/modules/project/types';
import ProjectMixin from '@/mixins/project';
import { searchModule } from '@/store/modules/search';

@Component({
    components: { TableModel },
    filters: {
        typeProject,
        models,
        banks,
        projectPhase
    }
})
export default class Project extends Mixins(ProjectMixin) {
    activeTooltip = false;
    @Prop({ default: false, type: Boolean }) readonly isCustomer!: boolean;
    @Prop({
        default: () => ({
            projectName: false,
            address: false
        })
    })
    readonly unselect!: Unselect;

    handleActiveTooltip(value: boolean) {
        if (this.type === 'map') {
            this.activeTooltip = value;
        }
    }

    get updatedAt() {
        if (!this.project.updatedAt) return '';
        return moment(this.project.updatedAt).locale('es').fromNow();
    }

    addOrRemoveFavorite() {
        searchModule.addOrRemoveFavorite(this.project._id);
    }
}
