import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { savedSearchModule } from '@/store/modules/savedSearch';
import { searchModule } from '@/store/modules/search';
import { priceProject } from '@/filters/price-project';
import { date } from '@/filters/date';
import ChangeViewMixin from './change-view';
import { Project } from '@/interfaces/project';

@Component({
    filters: { priceProject, date }
})
export default class ProjectMixin extends Mixins(ChangeViewMixin) {
    @Prop({ required: true }) readonly project!: Project;
    @Prop({ default: false }) readonly showChecked!: boolean;
    @Prop({ type: String, default: 'list' }) readonly type!: string;

    get textChecked() {
        if (!this.checked && this.type === 'map') {
            return 'Seleccionar proyecto';
        } else if (this.checked) {
            return 'Proyecto seleccionado';
        } else {
            return 'Seleccionar proyecto para enviar email';
        }
    }

    get favorite() {
        if (!searchModule.searchSelected) return false;
        return !!searchModule.searchSelected.customer.favorites.find(
            id => id === this.project._id
        );
    }

    get checked(): boolean {
        return projectModule.filter.only?.find(id => id === this.project._id)
            ? true
            : false;
    }

    set checked(value: boolean) {
        projectModule.upsertProjects(this.project._id);
    }

    activePanel = '2';

    get models() {
        const project = this.projects.data.find(
            ({ project_id }) => project_id === this.project.project_id
        );
        return project
            ? project.typologies || { page: 1, total: 0, data: [] }
            : { page: 1, total: 0, data: [] };
    }

    get nextModels() {
        return this.models.data.length < this.models.total;
    }

    get projects() {
        return projectModule.projects;
    }

    @Watch('filter', {
        deep: true
    })
    handleFilter() {
        this.activePanel = '2';
    }

    async loadModels(load?: boolean) {
        if (this.activePanel === '1' && !load) {
            this.activePanel = '2';
        } else {
            if (this.models.data.length < 1 || (this.nextModels && load)) {
                await this.getModels();
            }
            this.activePanel = '1';
        }
    }

    created() {
        this.chageView();
        window.addEventListener('resize', this.chageView);
    }

    destroyed() {
        window.removeEventListener('resize', this.chageView);
    }

    chageView() {
        if (window.innerWidth <= 992 && projectModule.view === 'detail') {
            this.setView('list');
        }
    }

    destroy() {
        projectModule.discardProject(this.project._id);
    }

    async hiddenPanelOrSearch() {
        if (this.nextModels) {
            await this.loadModels(true);
        } else {
            this.activePanel = '2';
        }
    }

    getModels(projectId?: number) {
        return projectModule.findTypologiesByProjectId(
            projectId || this.project.project_id
        );
    }

    get link() {
        if (savedSearchModule.savedSearchSelected) {
            return `/project-saved-search/${this.project.project_id}/${savedSearchModule.savedSearchSelected._id}`;
        } else if (searchModule.searchSelected) {
            return `/email-search/project/${this.project.project_id}/${searchModule.searchSelected._id}`;
        }
        return `/project/${this.project.project_id}`;
    }

    get filter() {
        return projectModule.filter;
    }
}
